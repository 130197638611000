/*
 * Base button styles
 * =================================== */ 
.base {
  /* style reset */
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
  
  /* custom styles */
  align-items: center;
  border-radius: 2px;
  color: black;
  display: inline-flex;
  flex-direction: row;
  font-size: var(--button-font-size, 1rem);
  font-weight: 600;
  justify-content: center;
  padding: 0.125rem 0.5rem;
  min-width: 2rem;
  min-height: 2rem;
  opacity: 1;
  transition: opacity 1s var(--cubic-ease);
}
.base.base:disabled {
  cursor: default;
  opacity: 0.16;
}

.iconOnly {
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
}
.icon path,
.icon circle {
  fill: currentColor;
}

/*
 * Primary and default
 * =================================== */ 
.primary {
  background-color: var(--button-surface, rgba(0, 0, 0, 0.05));
}
:is(
  .primary, 
  .default,
):hover:not([disabled]) {
  background-color: rgba(0, 0, 0, 0.1);
}
:is(
  .primary, 
  .default,
):active:not([disabled]) {
  background-color: rgba(0, 0, 0, 0.15);
}

/*
 * Reverse
 * =================================== */ 
.default {
  background-color: var(--button-surface, transparent);
}
.reverse {
  background-color: transparent;
  color: rgba(255,255,255,0.64);
  font-style: italic;
}

:is(
  .reverse
):hover:not([disabled]) {
  background-color: rgba(255,255,255,0.16);
  color: white;
}
:is(
  .reverse
):active:not([disabled]) {
  background-color: rgba(255,255,255,0.08);
  color: white;
}

/*
 * Large
 * =================================== */ 
.large {
  min-height: 3.5rem;
  padding-inline: 1rem;
  font-size: 1.25rem;
}