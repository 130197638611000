.base {
  align-items: center;
  background-color: var(--surface);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  height: var(--nav-height);
  justify-content: space-between;
  left: 0;
  padding-inline: var(--page-margin);
  padding-block-end: var(--bottomWithSafety);
  position: fixed;
  right: 0;
  width: 100%;
}

@media SCREEN and (display-mode: standalone) and (max-width: 499px) {
  .base {
    height: unset;
    padding-block-end: 24px;
  }
}

.base::before,
.base::after {
  background-color: var(--surface);
  content: '';
  height: var(--page-margin);
  position: absolute;
  top: calc(var(--page-margin) * -1);
  left: 0;
  right: 0;
}
.base::after {
  border-top: 0.5px solid rgba(0, 0, 0, 0.16);
  left: var(--page-margin);
  right: var(--page-margin);
}

.menuButton {
  /* style reset */
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
  
  /* custom styles */
  background-color: #1E1248;
  background-image: radial-gradient(
  circle at 0% 0%,
  #E9CC35 0%, #E9CC35 26%, 
  #EC696C 26%, #EC696C 42%,
  #9B3B7A 42%, #9B3B7A 58%,
  #501A79 58%, #501A79 72%,
  #1E1248 72%, #1E1248 100%);
  background-position-x: 0;
  background-repeat: no-repeat;
  border-radius: 999px;
  height: 2rem;
  position: relative;
  width: 2rem;
  z-index: var(--z-index-menuButton);
  transform: scale(1);
  transition: transform 0.25s var(--cubic-ease), background-position 0.15s ease-in;
}
.menuButton:hover {
  transform: scale(1.02);
}
.menuButton:active {
  transform: scale(0.96);
}
.menuButton[data-open] {
  background-position: -1.1rem -1.1rem;
}

.menuButton:before,
.menuButton:after {
  /* var(--surface) */
  background-color: rgba(255, 254, 250, 0); 
  border-radius: 999px;
  content: '';
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 1rem;
  transition: none;
}
.menuButton:before {
  transform: translate(-50%, -50%) rotate(0deg);
}
.menuButton:after {
  transform: translate(-50%, -50%) rotate(0deg);
}
.menuButton[data-open]:before {
  background-color: rgba(255, 254, 250, 1);
  transform: translate(-50%, -50%) rotate(45deg);
  transition: transform 0.5s var(--cubic-ease), background-color 0.5s var(--cubic-ease);
}
.menuButton[data-open]:after {
  background-color: rgba(255, 254, 250, 1);
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: transform 0.5s var(--cubic-ease), background-color 0.5s var(--cubic-ease);
}

h1[id="logo"] {
  bottom: var(--bottomWithSafety);
  left: max(var(--page-margin), env(safe-area-inset-left));
  flex-grow: 1;
}

.menuWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 0;
}