.base {
    position: relative;
}

.actualCheckbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.srOnly {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.svgContainer {
    height: var(--checkbox-size);
    opacity: var(--checkbox-opacity, 1);
    width: var(--checkbox-size);
}