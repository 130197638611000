:root {
  /* half page-margin (1rem) = 8px */
  --card-outer-border-stroke: 8px;
  --card-outer-border-stroke-doubled: 16px;
}

.base {
  --card-padding: 1rem;
  align-items: stretch;
  background-color: var(--card-surface, var(--surface));
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: flex-start;
  margin: 0;
  max-width: calc(100vw - (var(--page-margin) * 2));
  padding: 0;
  position: relative;
  transition: left 500ms var(--cubic-ease), 
    opacity 300ms var(--cubic-ease);
}
.flush {
  max-width: 100vw;
  transition: none;
}

.card {
  border-radius: var(--card-border-radius, 1.75rem);
  border: 0.5px solid var(--card-border-color, rgba(0,0,0,0.5));
  height: calc(100vh - var(--nav-height) - (var(--page-margin) * 2));
  left: var(--page-margin);
  position: absolute;
  top: var(--page-margin);
  width: calc(100vw - (var(--page-margin) * 2));
}
.card.floating {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08), 1px 1px 2px rgba(0, 0, 0, 0.24),  0 0 0 var(--card-outer-border-stroke) var(--card-surface, var(--surface));
}

.titleWrap {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: var(--card-title-color, var(--text-color));
  display: block;
  flex-grow: 1;
  font-family: 'gooper_deck', sans-serif;
  font-size: 1.25rem;
  /* font-style: italic; */
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0.75rem var(--card-padding, 0)  0;
  text-align: left;
}

.headerActions {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}

.childrenWrap {
  flex: 1 1 auto;
  overflow-y: auto;
}