
/**
  * Card box
  * =========================================== */
.cardBox {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}



/**
  * Cards
  * =========================================== */
.nowCard {
  height: 100%;
}
.nextCard {
  /* var(--coral) @ 72% */
  --card-surface: #F19395;
}
.laterCard {
  --card-surface: var(--gold);
}

/* Stacked layout */
@media SCREEN and (max-width: 767px) {
  .card.nextCard {
    --card-border-radius: 1.5rem;
    bottom: calc(var(--live-area-bottom) + var(--page-margin) + var(--card-outer-border-stroke));
    height: auto;
    max-width: calc(100vw - (var(--page-margin) * 2) - var(--card-outer-border-stroke-doubled));
    opacity: 0.25;
    top: calc(var(--card-header-height) + 0.25rem + var(--card-outer-border-stroke));
  }
  .card.laterCard {
    --card-border-radius: 1.25rem;
    bottom: calc(var(--live-area-bottom) + (var(--page-margin) * 2) + var(--card-outer-border-stroke));
    height: auto;
    left: 100vw;
    max-width: calc(100vw - (var(--page-margin) * 2) - var(--card-outer-border-stroke-doubled) - var(--card-outer-border-stroke));
    opacity: 0.25;
    position: absolute;
    top: calc((var(--card-header-height) * 2) + 0.5rem + var(--card-outer-border-stroke-doubled));
  }
}

/* Side-by-side layout */
@media SCREEN and (min-width: 768px) {
  .card.nowCard,
  .card.nextCard,
  .card.laterCard {
    height: 100%;
    width: calc(100vw / 3);
  }
  .card.nextCard,
  .card.laterCard {
    bottom: calc(var(--live-area-bottom) + var(--page-margin));
    top: 0;
    position: absolute;
  }
}