.header {
  align-items: center;
  border-top-left-radius: var(--dialog-border-radius);
  border-top-right-radius: var(--dialog-border-radius);
  border-bottom: 1px dotted rgba(255,255,255,0.16);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--dialog-padding) var(--dialog-padding) calc(var(--dialog-padding) * 0.5);
  z-index: 1;
}

.content {
  background-color: var(--dialog-surface, var(--magma-15));
  overflow: hidden;
}

.headingActionBar {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-block-start: calc(var(--dialog-padding) * -0.5);
  margin-inline-end: calc(var(--dialog-padding) * -0.5);
}