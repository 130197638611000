.rootMenu[data-open],
.rootMenu:hover {
  background: var(--menu-surface-active-unfocused);
}
  
  .menu {
    background: var(--menu-surface);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0.325rem;
    border-radius: 0.5rem;
    box-shadow: var(--menu-shadow);
    outline: 0;
  }