.base {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.loader {
  box-sizing: border-box;
  border-radius: 999px;
  height: 8px;
  width: max(32%, 72px);
  background-size: calc(max(32%, 72px) * 2) 8px;
  background-color: red;
  background-image: linear-gradient(
    45deg,
    var(--magma-14) 0% 10%,
    var(--brand-gold) 10% 30%,
    var(--coral) 30% 50%,
    var(--magma-9) 50% 70%,
    var(--magma-12) 70% 90%,
    var(--magma-14) 90% 100%
  );
  animation: stripeMove 3s linear infinite;
}

@keyframes stripeMove {
  to {
    background-position: 1000px 0;
  }
}
