.base {
  display: inline-block;
  color: var(--magma-12);
  font-family: 'gooper_deck', sans-serif;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  /* 
  Gooper rides high on it's base line,
  so even though it's "centered" it 
  isn't centered. 
  */
  margin-block-start: 0.125em;
  text-align: left;
}