/* tokens */
:root {
  /* color */
  --magma-1: #FCFDC6;
  --magma-2: #F8DFA8;
  --magma-3: #F4C28D;
  --magma-4: #F1A376;
  --magma-5: #EA8666;
  --magma-6: #E06962;
  --magma-7: #CD546A;
  --magma-8: #B54674;
  --magma-9: #9B3B7A;
  --magma-10: #81317D;
  --magma-11: #69257D;
  --magma-12: #501A79;
  --magma-13: #36126C;
  --magma-14: #1E1248;
  --magma-15: #0B0922;
  --magma-16: #000004;
  --gold: #DFCF73;
  --brand-gold: #E9CC35;
  --coral: #EC696C;
  --cyan: #9DD5E5;
  --off-white-1: #FFFEFA;
  --off-white-2: #FDFAF0;
  --off-white-3: #FDF8E8;

  --surface: var(--off-white-1);
  --text-color: #16312E;

  /* layout */
  --page-margin: 1rem;
  --nav-height: 2rem;
  --card-header-height: 2rem;
  --checkbox-size: 18px;

  /* live area */
  --bottomWithSafety: max(var(--page-margin), calc(env(safe-area-inset-bottom) + 0.25rem));
    /* 
      + 2rem: nav
      + 1rem: nav top border
      + 1rem: page-margin
      = 4rem + env(safe-area-inset-bottom)
     */
  --live-area-bottom: calc(64px + env(safe-area-inset-bottom));
     /* 
       + card-header = 2rem;
       + page-margin = 1rem;
       = 3rem + env(safe-area-inset-top)
      */
  --live-area-top: calc(3rem + env(safe-area-inset-top));

  /* menu */
  --menu-shadow: 2px 4px 12px rgba(0, 0, 0, 0.24), 0 0 0 1px rgba(0, 0, 0, 0.16);
  --menu-surface: rgba(11, 9, 34, 0.8); /* Magma 15 */
  --menu-surface-highlighted: var(--coral);
  --menu-surface-active-unfocused: rgba(0, 0, 0, 0.24);
  --menu-item-padding-x: 0.625rem;

  /* animation */
  --cubic-ease: cubic-bezier(0.22, 1, 0.36, 1);

  /* typography */
  --text-color-reverse: #fff;

  /* z-index */
  --z-index-menuButton: 9999999;
  --z-index-dialog-curtain: 99999999;
  --z-index-dialog: 999999999;
  --z-index-toast: 9999999999;
}

/* reset */
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  -webkit-font-smoothing: antialiased;
}
input, button, textarea, select {
  font: inherit;
}

/* styles */
html, 
body,
#root,
div.App {
  background-color: var(--surface);
  color: var(--text-color);
  height: 100%;
  width: 100%;
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 100vh;
  justify-content: flex-start;
  text-align: center;
  width: 100vw;
}

p {
  color: var(--text-color);
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px dotted currentColor;
}
a:hover {
  border-bottom-style: solid;
}

hr {
  background-color: rgba(0, 0, 0, 0.16);
  border: none;
  height: 1px;
  margin: 1rem 0;
  width: 100%;
}


@font-face {
  font-family: 'gooper_deck';
  src: url('./static/fonts/gooperdeck7-bolditalic-trial-webfont.woff2') format('woff2'),
       url('./static/fonts/gooperdeck7-bolditalic-trial-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'gooper_deck';
  src: url('./static/fonts/gooperdeck7-semibold-trial-webfont.woff2') format('woff2'),
       url('./static/fonts/gooperdeck7-semibold-trial-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'gooper_deck';
  src: url('./static/fonts/gooperdeck7-medium-trial-webfont.woff2') format('woff2'),
       url('./static/fonts/gooperdeck7-medium-trial-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'gooper_deck';
  src: url('./static/fonts/gooperdeck7-mediumitalic-trial-webfont.woff2') format('woff2'),
       url('./static/fonts/gooperdeck7-mediumitalic-trial-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}