.item {
    --item-color: var(--off-white-1);
    align-items: center;
    background: none;
    border-radius: 0.25rem;
    border: none;
    box-sizing: border-box;
    color: var(--item-color);
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    line-height: 1.8;
    margin: 0;
    min-height: 2.5rem;
    min-width: 8rem;
    padding-inline: var(--menu-item-padding-x);
    outline: 0;
    text-align: left;
    text-decoration: none;
    width: 100%;
  }
  a.item, 
  a.item:visited,
  a.item:active,
  a.item:hover {
    border: none;
  }
  
  :is(
    .item:focus,
    .item[data-nested][data-open]:not([data-focus-inside]),
    .item:hover
  ):not([disabled]) {
      background: var(--menu-surface-highlighted);
      box-shadow: var(--menu-shadow);
      color: white;
  }
  .item[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .item[data-focus-inside][data-open] {
    background: var(--menu-surface-active-unfocused);
  }