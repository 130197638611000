.base {
  background-color: #1E1248;
  background-image: radial-gradient(
  circle at 0% 0%,
  #E9CC35 0%, #E9CC35 26%, 
  #EC696C 26%, #EC696C 42%,
  #9B3B7A 42%, #9B3B7A 58%,
  #501A79 58%, #501A79 72%,
  #1E1248 72%, #1E1248 100%);
  background-position-x: 0;
  background-repeat: no-repeat;
  border-radius: 999px;
  height: 2rem;
  position: relative;
  width: 2rem;
  z-index: var(--z-index-menuButton);
  transform: scale(1);
  transition: transform 0.25s var(--cubic-ease), background-position 0.15s ease-in;
}