.base {
  --input-min-height: 3rem;
  /* Vertically center a single line of text at the 
    input's min height. */
  --input-padding-block: calc( (var(--input-min-height) - (var(--input-font-size) * var(--input-line-height))) / 2 );
  --input-font-size: 1rem;
  /* Line height is used in component math and MUST be a unitless value */
  --input-line-height: 1.6;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: flex-start;
  min-height: var(--input-min-height);
  padding: 0 var(--card-padding, 0);
  width: 100%;
}
.base:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.base:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px blue;
  background-color: rgba(0, 0, 255, 0.05);
}

.inputWrap {
  display: grid;
  flex: 1 1 auto;
}

/**
  The inputWrap::after pseudo-element should match the content and styles
  of the textarea exactly, as it's used to force an "auto height" effect
  on the textarea.
  */
.inputWrap:after {
  content: attr(data-value) " ";
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  word-wrap: break-word;
  grid-area: 1 / 1 / 2 / 2;
  white-space: pre-wrap;
  visibility: hidden;
  overflow: hidden;
  padding-block: var(--input-padding-block);
}

.input {
  align-self: stretch;
  background-color: transparent;
  border: none;
  color: var(--input-color, var(--text-color));
  flex: 1 auto;
  font-size: var(--input-font-size);
  grid-area: 1 / 1 / 2 / 2;
  line-height: var(--input-line-height);
  overflow: hidden;
  padding-block: var(--input-padding-block);
  resize: none;
}
.input:focus {
  outline: 2px solid transparent;
}
.input::placeholder {
  font-style: italic;
}

.isComplete .input {
  opacity: var(--input-opacity, 0.64);
  text-decoration: var(--input-text-decoration, line-through);
}

.checkbox {
  /* Vertically center the checkbox when todo is at min-height */
  margin-block-start: calc((var(--input-min-height) - var(--checkbox-size)) / 2)
}