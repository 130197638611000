.base {
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  margin: 0;
  padding: 2rem;
  width: 100%;
}

.callout {
  /* color: var(--coral); */
  font-family: 'gooper_deck', sans-serif;
  font-size: 2.5rem;
  line-height: 1.4;
  /* font-style: italic; */
  /* font-weight: 700; */
}

.threeColumn {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}