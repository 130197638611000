.base {
    align-items: flex-end;
    background-color: var(--surface);
    /* border: 1px solid rgba(0, 0, 0, 0.05); */
    bottom: 0;
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    gap: 0.125rem;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0.25rem 0.5rem;
    width: auto;
}