.base {
  --input-color: white;
  --input-text-decoration: none;
  --input-opacity: 0.88;
  --checkbox-opacity: 0.24;
  --dialog-surface: var(--magma-15);
}
.dateFilterSelect {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: var(--page-margin);
  z-index: 1;
}

.copyButton {
  /* // button reset */
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none; /* This removes the default drop-down arrow */
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;

  /* // custom styles */
  background-color: transparent;
  background-color: rgba(255,255,255,0.16);
  color: var(--input-color);
  font-weight: 500;
  border-radius: 999px;
  min-height: 2.5rem;
  padding: 0 0.75rem;
  text-align: center;
}
.copyButton:hover {
  background-color: rgba(255,255,255,0.96);
}
.copyButton:active {
  background-color: rgba(255,255,255,0.88);
}

@media SCREEN and (min-width: 768px) {
  .copyButton {
    min-height: 2rem;
  }
}