.base {
  /* reset */
  padding: 0;
  margin: 0;
  line-height: 1;
}

.button {
  /* style reset */
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
}

.indicator path {
  /* fill: var(--indicator-active-color, var(--magma-12)); */
  stroke-width: 1px;
  stroke:var(--indicator-active-color, var(--magma-12));
}

.indicator.active path {
  fill: var(--indicator-active-color, var(--magma-12));
  stroke: var(--indicator-active-color, var(--magma-12));
}