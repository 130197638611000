:root {
  --dialog-padding: 1rem;
  --dialog-border-radius: 1.5rem;
}

.overlay {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: var(--z-index-dialog-curtain);
}

.dialog {
  background-color: var(--dialog-surface, var(--magma-15));
  border-radius: var(--dialog-border-radius);
  border: 1.5px solid rgba(255, 255, 255, 0.32);
  bottom: max(var(--page-margin), env(safe-area-inset-bottom));
  box-shadow: var(--menu-shadow);
  box-sizing: border-box;
  color: var(--dialog-color, white);
  display: flex;
  flex-direction: column;
  left: var(--page-margin);
  position: absolute;
  right: var(--page-margin);
  top: max(var(--page-margin), env(safe-area-inset-top));
  z-index: var(--z-index-dialog);
}
@media SCREEN and (min-width: 768px) {
  .dialog {
    max-width: 32rem;
    left: 50%;
    right: inherit;
    transform: translateX(-50%);
  }
}
.dialog p {
  color: var(--dialog-color, white);
}

.dialogDescription {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0 var(--dialog-padding);
}
.heading {
  color: var(--coral);
  color: var(--magma-4);
  font-family: 'gooper_deck', sans-serif;
  font-size: 1.25rem;
  /* font-style: italic; */
  font-weight: 500;
  line-height: 1;
  margin: 0;
  text-align: left;
}