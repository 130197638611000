:root {
  --toast-min-height: 2.5rem;
}

@keyframes slideInOut {
  0%, 
  100% { 
    bottom: calc(var(--toast-min-height) * -1.125); 
    opacity: 0;
  }
  8%, 
  96% { 
    bottom: max(calc(var(--page-margin) / 2), env(safe-area-inset-bottom)); 
    opacity: 1; 
  }
}

.container {
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  z-index: var(--z-index-toast);
}
.container.open {
  animation-name: slideInOut;
  animation-duration: 5s;
  /* animation-fill-mode: forwards; */
}
.base {
  align-items: center;
  background-color: white;
  border-radius: 999px;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.12), 1px 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid black;
  color: black;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  justify-content: center;
  line-height: 1.2;
  min-height: var(--toast-min-height);
  padding: 0.25rem 1rem;
}