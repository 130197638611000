.buttonReset {
    /* style reset */
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-family: inherit;
    padding: 0;
}

.base.base {
  align-items: center;
  color: var(--text-color-reverse);
  display: inline-flex;
  gap: 4px;
  font-size: 0.625rem;
  font-weight: 600;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-inline: 0.25rem;
  min-width: 40px;
}

.icon {
  height: 1rem;
  width: 1rem;
}