.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  max-width: 40rem;
  padding: var(--page-margin);
  text-align: left;
}

.content h1,
.content h2,
.content h3 {
  font-size: 2rem;
  line-height: 1.2;
  color: var(--magma-12);
  font-family: 'gooper_deck', sans-serif;
  font-weight: 700;
  margin: 0;
  /* 
  Gooper rides high on it's base line,
  so even though it's "centered" it 
  isn't centered. 
  */
  margin-block-start: 0.125em;
  margin-bottom: 0;
  text-align: left;
}
.content h2 {
  font-size: 1.5rem;
  line-height: 1.4;
}
.content h3 {
  font-size: 1.25rem;
  line-height: 1.4;
}