/**
  * Swipable list
  * =========================================== */
  .swipeableListItem {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
  
  }
  .swipeableListItem:last-child {
    border-bottom: none;
    margin-bottom: 6rem;
  }