.base {
  /* css reset */
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none; /* This removes the default drop-down arrow */
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;

  /* custom styles */
  color: black;
  background-color: white;
  border-radius: 999px;
  min-height: 2.5rem;
  padding: 0 0.75rem;
  text-align: center;
}
.base:hover {
  background-color: #f5f5f5;
}
.base:active {
  background-color: #e0e0e0;
}